<template>
  <div
    class="payments-kyc-modal"
    :class="{ mobile: isMobile }"
    data-t="payments-kyc-modal"
  >
    <div v-if="isMobile" class="close-wrapper">
      <div class="close-icon">
        <StIcon name="cross-large" @click="handleClose" />
      </div>
    </div>
    <div class="content">
      <div class="header-section">
        <StIcon
          :name="isForbidden ? 'circle-cross' : 'id-card-clip-solid'"
          :size="isMobile ? 48 : 64"
          class="header-icon"
        />
        <div class="text-section">
          <h2>
            {{
              isForbidden
                ? t('payments.kycVerification.titleForbidden')
                : t('payments.kycVerification.titleSuccess')
            }}
          </h2>
          <span>{{
            isForbidden
              ? t('payments.kycVerification.subtitleForbidden')
              : t('payments.kycVerification.subtitleSuccess')
          }}</span>
        </div>
      </div>
      <div class="button-wrapper">
        <StButton
          :label="
            isForbidden
              ? t('payments.kycVerification.buttonForbidden')
              : t('payments.kycVerification.buttonSuccess')
          "
          :type="isForbidden ? 'gray' : 'primary'"
          class="submit-button"
          size="l"
          to="/personal/kyc"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const isForbidden = ref(false)
const { isMobile } = usePlatform()
const route = useRoute()
const router = useRouter()

function handleClose() {
  router.replace({ query: {} })
}

onBeforeMount(() => {
  const { query } = route
  isForbidden.value = query.type === 'rejected'
})
</script>

<style scoped>
.payments-kyc-modal {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
  align-items: center;
  justify-content: center;

  width: 596px;
  padding: var(--spacing-400) 0;

  background-color: var(--background-base);
  border-radius: var(--border-radius-200);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-300);
}

.header-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
  align-items: center;
}

.text-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: center;
}

.header-icon {
  color: var(--icon-tertiary);
}

h2 {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-xl-semibold);
}

span {
  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
}

.payments-kyc-modal.mobile {
  position: relative;

  width: auto;
  margin-top: var(--spacing-250);
  padding: var(--spacing-200) var(--spacing-200) 0;

  text-align: center;

  .close-wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    display: flex;
    justify-content: flex-end;

    width: 100%;
    padding: var(--spacing-050);

    .close-icon {
      padding: var(--spacing-125);
    }
  }

  .content {
    gap: var(--spacing-150);
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }

  h2 {
    font: var(--mobile-headline-semibold);
  }

  span {
    font: var(--mobile-text-content-regular);
  }

  .text-section {
    gap: var(--spacing-100);
  }

  .button-wrapper {
    width: 100%;
    padding: var(--spacing-200);

    .submit-button {
      width: 100%;
    }
  }
}
</style>
